
/* eslint id-length: 0 */
/* eslint-disable */
// last generation from Tue Jun 11 2024 17:55:09 GMT+0200 (Central European Summer Time)

import Requestor from "@api/requestor";

class API {

    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    addressGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Address/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.Email	Email
     * @param {string=}		 bodyParameters.Username	Username
     * @param {string=}		 bodyParameters.Password	Password
     * @param {string=}		 bodyParameters.ConfirmPassword	ConfirmPassword
     * @param {array=}		 bodyParameters.Groups	Groups
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationCreateUser(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Administration/user', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Users	Users
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationLockUsers(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Administration/lock', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Users	Users
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationUnlockUsers(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Administration/unlock', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationGetGroups() {
        return Requestor.run('/api/Administration/groups', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array}		 queryParameters.Filters	Filters
     * @param {integer}		 queryParameters.Length	Length
     * @param {Object}		 queryParameters.Sort	Sort
     * @param {integer}		 queryParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationGetUsers(queryParameters = {}) {
        return Requestor.run('/api/Administration/users', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array}		 queryParameters.Filters	Filters
     * @param {integer}		 queryParameters.Length	Length
     * @param {Object}		 queryParameters.Sort	Sort
     * @param {integer}		 queryParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationGetChildrenUsers(id, queryParameters = {}) {
        return Requestor.run('/api/Administration/{id}/children-users', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationGetUserParents(id) {
        return Requestor.run('/api/Administration/{id}/user-parents', 'GET', {id});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationUserCanCreateUsers() {
        return Requestor.run('/api/Administration/user/can-create', 'GET', {});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    administrationSuggestPassword() {
        return Requestor.run('/api/Administration/suggest-password', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.DetectionTargetType	DetectionTargetType
     * @param {array}		 bodyParameters.SelectedFeatures	SelectedFeatures
     * @param {undefined=}		 bodyParameters.QueryParameters	QueryParameters
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    aggregatedFeaturesCarrierTreatmentStatistics(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AggregatedFeatures/CarrierTreatmentStatistics', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.DetectionTargetType	DetectionTargetType
     * @param {array}		 bodyParameters.SelectedFeatures	SelectedFeatures
     * @param {undefined=}		 bodyParameters.QueryParameters	QueryParameters
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    aggregatedFeaturesCarrierPlanSponsorStatistics(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AggregatedFeatures/CarrierPlanSponsorStatistics', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.DetectionTargetType	DetectionTargetType
     * @param {array}		 bodyParameters.SelectedFeatures	SelectedFeatures
     * @param {undefined=}		 bodyParameters.QueryParameters	QueryParameters
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    aggregatedFeaturesAggregatedStatistics(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AggregatedFeatures/AggregatedStatistics', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.DetectionTargetType	DetectionTargetType
     * @param {array}		 bodyParameters.SelectedFeatures	SelectedFeatures
     * @param {undefined=}		 bodyParameters.QueryParameters	QueryParameters
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    aggregatedFeaturesScatterChartFeatures(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AggregatedFeatures/ScatterChartFeatures', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.DetectionTargetType	DetectionTargetType
     * @param {array}		 bodyParameters.SelectedFeatures	SelectedFeatures
     * @param {undefined=}		 bodyParameters.QueryParameters	QueryParameters
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    aggregatedFeaturesTimelineChartFeatures(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AggregatedFeatures/TimelineChartFeatures', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertCommentGetAll(alertId) {
        return Requestor.run('/api/alert/{alertId}/comment', 'GET', {alertId});
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.Id	Id
     * @param {string}		 bodyParameters.Content	Content
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertCommentAddComment(alertId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/alert/{alertId}/comment', 'POST', {alertId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.Id	Id
     * @param {string}		 bodyParameters.Content	Content
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertCommentEditComment(alertId, bodyParameters = {}) {
        return Requestor.run('/api/alert/{alertId}/comment', 'PUT', {alertId}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertCommentRemoveComment(alertId, id) {
        return Requestor.run('/api/alert/{alertId}/comment/{id}', 'DELETE', {alertId, id});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerGetAlertsById(id, queryParameters = {}) {
        return Requestor.run('/api/AlertContainer/{id}/alerts', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer=}		 queryParameters.type	type
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerComputedViewGetAlertsById(id, queryParameters = {}) {
        return Requestor.run('/api/AlertContainer/{id}/alerts/computed-view', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerGetById(id, queryParameters = {}) {
        return Requestor.run('/api/AlertContainer/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerGetAssignableUsersForAlertContainer(id) {
        return Requestor.run('/api/AlertContainer/{id}/assign', 'GET', {id});
    }


    /**
     * @async
     * @param {string}		 userId	userId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerSetAssignment(id, userId) {
        return Requestor.run('/api/AlertContainer/{id}/assign/{userId}', 'PUT', {id, userId}, {});
    }


    /**
     * @async
     * @param {string}		 userId	userId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerRemoveAssignment(id, userId) {
        return Requestor.run('/api/AlertContainer/{id}/assign/{userId}', 'DELETE', {id, userId});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AlertContainer/search/all', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AlertContainer/search/all/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerSearchByType(type, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AlertContainer/search/{type}', 'POST', {type}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerComputedViewSearchByType(type, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AlertContainer/search/{type}/computed-view', 'POST', {type}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.Id	Id
     * @param {undefined}		 bodyParameters.Status	Status
     * @param {undefined}		 bodyParameters.Action	Action
     * @param {string=}		 bodyParameters.Comment	Comment
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertContainerSetValidationStatus(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AlertContainer/{id}/validation', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Alert/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertCanCreateAlert(type, id) {
        return Requestor.run('/api/Alert/canCreateAlert/{type}/{id}', 'GET', {type, id});
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetInformationForCreateAlert(type, additionalOptions = {}) {
        return Requestor.run('/api/Alert/createModalInfo/{type}', 'POST', {type}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 exportName	exportName
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @param {undefined=}		 bodyParameters.DetectionTargetType	DetectionTargetType
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertTableCustomExport(exportName, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/tableCustomExport/{exportName}', 'POST', {exportName}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertExportHistory(scenarioId, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/export/scenario/{scenarioId}/history', 'POST', {scenarioId}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters._	_
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetHistory(scenarioId, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/scenario/{scenarioId}/history', 'POST', {scenarioId}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSearchNodeAlerts(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/search/node-alerts', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSearchNodeAlertsComputedView(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/search/node-alerts/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/search/all', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSearchByType(type, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/search/{type}', 'POST', {type}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/search/all/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertComputedViewSearchByType(type, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/search/{type}/computed-view', 'POST', {type}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetByTypeByResourceId(type, id) {
        return Requestor.run('/api/Alert/{type}/{id}', 'GET', {type, id});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetContainersByAlertId(id) {
        return Requestor.run('/api/Alert/{id}/containers', 'GET', {id});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetAssignableUsersForAlert(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/assignables', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.AddedUserIds	AddedUserIds
     * @param {array=}		 bodyParameters.RemovedUserIds	RemovedUserIds
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSetAssignment(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/assign', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSetSelfAssignment(id) {
        return Requestor.run('/api/Alert/{id}/selfassign', 'PUT', {id}, {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {boolean}		 bodyParameters.ActivationStatus	ActivationStatus
     * @param {array}		 bodyParameters.Ids	Ids
     * @param {undefined=}		 bodyParameters.FromDate	FromDate
     * @param {undefined=}		 bodyParameters.ToDate	ToDate
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSetActivationStatuses(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/activationstatuses', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {undefined=}		 bodyParameters.FromDate	FromDate
     * @param {undefined=}		 bodyParameters.ToDate	ToDate
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertDelete(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/delete', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertEnableScenario(alertId, scenarioId) {
        return Requestor.run('/api/Alert/{alertId}/scenario/{scenarioId}/enable', 'PUT', {alertId, scenarioId}, {});
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertDisableScenario(alertId, scenarioId) {
        return Requestor.run('/api/Alert/{alertId}/scenario/{scenarioId}/disable', 'PUT', {alertId, scenarioId}, {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {boolean}		 bodyParameters.IdentityStatus	IdentityStatus
     * @param {array}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSetIdentityStatuses(bodyParameters = {}) {
        return Requestor.run('/api/Alert/identitystatuses', 'PUT', {}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.EnumIdentifier	EnumIdentifier
     * @param {integer}		 bodyParameters.EnumValueId	EnumValueId
     * @param {boolean}		 bodyParameters.Checked	Checked
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSetInvestigationGuideline(id, bodyParameters = {}) {
        return Requestor.run('/api/Alert/{id}/investigationguideline', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetNotes(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/{id}/notes', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertExportNotes(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/{id}/notes/export', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertGetActivities(id) {
        return Requestor.run('/api/Alert/{id}/activities', 'GET', {id});
    }


    /**
     * @async
     * @param {integer}		 targetId	targetId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer=}		 queryParameters.containerId	containerId
     * @param {integer=}		 queryParameters.alertId	alertId
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSearchRelatedAlerts(type, targetId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/{type}/{targetId}/related-alerts', 'POST', {type, targetId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 targetId	targetId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer=}		 queryParameters.containerId	containerId
     * @param {integer=}		 queryParameters.alertId	alertId
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertSearchRelatedAlertsComputedView(type, targetId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Alert/{type}/{targetId}/related-alerts/computed-view', 'POST', {type, targetId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object=}		 bodyParameters.Fields	Fields
     * @param {Object=}		 bodyParameters.OldFields	OldFields
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertMetadataSaveForm(alertId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/AlertMetadata/{alertId}', 'POST', {alertId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertMetadataGetForm(alertId) {
        return Requestor.run('/api/AlertMetadata/{alertId}', 'GET', {alertId});
    }


    /**
     * @async
     * @param {integer}		 formType	formType
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertMetadataGetMetadataFormDynamicValues(formType) {
        return Requestor.run('/api/AlertMetadata/dynamic-values/{formType}', 'GET', {formType});
    }


    /**
     * @async
     * @param {string}		 state	state
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 undefinedParameters.body	body
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertWorkflowHandleTransition(alertId, state, actionType, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/alert/qualification/{alertId}/transition/{state}/{actionType}', 'POST', {alertId, state, actionType}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.NextState	NextState
     * @param {string=}		 bodyParameters.Action	Action
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {array=}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertWorkflowHandleBulkTransition(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/transition/bulk', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertWorkflowRevertTransition(alertId, additionalOptions = {}) {
        return Requestor.run('/api/alert/qualification/{alertId}/revert', 'POST', {alertId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 actionType	actionType
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertWorkflowActionConfiguration(actionType) {
        return Requestor.run('/api/alert/qualification/configuration/{actionType}', 'GET', {actionType});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.Identifier	Identifier
     * @param {number}		 bodyParameters.Amount	Amount
     * @param {undefined}		 bodyParameters.Currency	Currency
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alertWorkflowSetAmount(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/alert/qualification/{id}/amount', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {boolean}		 bodyParameters.Coordination	Coordination
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    alfaContainerSetCoordination(id, bodyParameters = {}, queryParameters = {}) {
        return Requestor.run('/api/AlfaContainer/{id}/coordinate', 'PUT', {id}, queryParameters, bodyParameters);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    authenticationLoginPage() {
        return Requestor.run('/Authentication/login', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Email	Email
     * @param {string}		 bodyParameters.Password	Password
     * @param {boolean}		 bodyParameters.RememberMe	RememberMe
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.returnState	returnState
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    authenticationLogin(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/Authentication/login', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.provider	provider
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    authenticationExternalLoginPage(queryParameters = {}) {
        return Requestor.run('/Authentication/externallogin', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    authenticationLogout(additionalOptions = {}) {
        return Requestor.run('/Authentication/logout', 'POST', {}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    bankAccountGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/BankAccount/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Claim/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {string}		 referenceId	referenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimGetByReferenceId(referenceId, queryParameters = {}) {
        return Requestor.run('/api/Claim/reference/{referenceId}', 'GET', {referenceId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Claim/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Claim/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Claim/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Claim/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Claim/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimGetClaimMetaData(id, queryParameters = {}) {
        return Requestor.run('/api/Claim/{id}/history/meta-data', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.ResourceType	ResourceType
     * @param {undefined}		 bodyParameters.HistoryType	HistoryType
     * @param {undefined=}		 bodyParameters.SearchParameters	SearchParameters
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimGetClaimDetailsByClaimId(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Claim/{id}/claim-details', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Claim/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Claim/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.NewRecommendation	NewRecommendation
     * @param {undefined}		 bodyParameters.OldRecommendation	OldRecommendation
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    claimSetPaymentRecommendation(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Claim/{id}/payment-recommendation', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceGetAllByPage(type, id, queryParameters = {}) {
        return Requestor.run('/api/CmCorrespondence/{type}/{id}', 'GET', {type, id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 correspondenceId	correspondenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceGetById(type, id, correspondenceId, queryParameters = {}) {
        return Requestor.run('/api/CmCorrespondence/{type}/{id}/{correspondenceId}', 'GET', {type, id, correspondenceId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 entityId	entityId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.AccessLevel	AccessLevel
     * @param {undefined=}		 bodyParameters.CorrespondenceFormat	CorrespondenceFormat
     * @param {string=}		 bodyParameters.Body	Body
     * @param {string=}		 bodyParameters.Subject	Subject
     * @param {integer=}		 bodyParameters.TemplateId	TemplateId
     * @param {array=}		 bodyParameters.Recipients	Recipients
     * @param {array=}		 bodyParameters.Attachments	Attachments
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceCreate(entityType, entityId, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/CmCorrespondence/{entityType}/{entityId}', 'POST', {entityType, entityId}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.AccessLevel	AccessLevel
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceUpdate(id, bodyParameters = {}, queryParameters = {}) {
        return Requestor.run('/api/CmCorrespondence/update/{id}', 'PUT', {id}, queryParameters, bodyParameters);
    }


    /**
     * @async
     * @param {string}		 endpoint	endpoint
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceGetEndpointValues(endpoint) {
        return Requestor.run('/api/CmCorrespondence/endpoints/{endpoint}', 'GET', {endpoint});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceGetEmailTemplates() {
        return Requestor.run('/api/CmCorrespondence/getEmailTemplates', 'GET', {});
    }


    /**
     * @async
     * @param {integer}		 templateId	templateId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmCorrespondenceGetTemplateInfoById(type, id, templateId) {
        return Requestor.run('/api/CmCorrespondence/{type}/{id}/getTemplateInfoById/{templateId}', 'GET', {type, id, templateId});
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageNumber	pageNumber
     * @param {integer}		 queryParameters.pageSize	pageSize
     * @param {integer}		 queryParameters.totalCount	totalCount
     * @param {string}		 queryParameters.referenceCode	referenceCode
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmsClaimGetPagedClaims(queryParameters = {}) {
        return Requestor.run('/api/cms-claims', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 accessLevel	accessLevel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmsClaimGetAssignableUsers(accessLevel) {
        return Requestor.run('/api/cms-claims/assignables/{accessLevel}', 'GET', {accessLevel});
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array=}		 queryParameters.ClaimIds	ClaimIds
     * @param {string=}		 queryParameters.AssigneeId	AssigneeId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    cmsClaimAssignUser(queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/cms-claims/assign', 'POST', {}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    collaborationGetCollaborationByAlertId(alertId) {
        return Requestor.run('/api/Collaboration/alert/{alertId}', 'GET', {alertId});
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.CollaborationId	CollaborationId
     * @param {array=}		 bodyParameters.AdditionalEmails	AdditionalEmails
     * @param {boolean}		 bodyParameters.IsDisclosed	IsDisclosed
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    collaborationSendCollaborationRequest(alertId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Collaboration/alert/{alertId}/collaborate', 'POST', {alertId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.ClaimId	ClaimId
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {undefined}		 bodyParameters.CorrespondenceFormat	CorrespondenceFormat
     * @param {string=}		 bodyParameters.AdditionalInformation	AdditionalInformation
     * @param {integer=}		 bodyParameters.RecipientId	RecipientId
     * @param {undefined}		 bodyParameters.RecipientType	RecipientType
     * @param {string=}		 bodyParameters.ReasonFreeText	ReasonFreeText
     * @param {undefined}		 bodyParameters.Reason	Reason
     * @param {undefined=}		 bodyParameters.ExonerationStartDate	ExonerationStartDate
     * @param {undefined=}		 bodyParameters.ExonerationEndDate	ExonerationEndDate
     * @param {string=}		 bodyParameters.ReferenceCode	ReferenceCode
     * @param {string=}		 bodyParameters.Title	Title
     * @param {string}		 bodyParameters.Fullname	Fullname
     * @param {string=}		 bodyParameters.Firstname	Firstname
     * @param {string=}		 bodyParameters.Lastname	Lastname
     * @param {string=}		 bodyParameters.Address	Address
     * @param {string=}		 bodyParameters.City	City
     * @param {string=}		 bodyParameters.PostalCode	PostalCode
     * @param {string=}		 bodyParameters.Email	Email
     * @param {undefined}		 bodyParameters.Template	Template
     * @param {undefined=}		 bodyParameters.MailExportDate	MailExportDate
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    correspondenceCreateCorrespondence(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Correspondence', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.SearchModel	SearchModel
     * @param {array}		 bodyParameters.Targets	Targets
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    correspondenceSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Correspondence/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 endpoint	endpoint
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    correspondenceGetEndpointValues(resourceType, endpoint) {
        return Requestor.run('/api/Correspondence/endpoints/{resourceType}/{endpoint}', 'GET', {resourceType, endpoint});
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    correspondenceDeleteById(queryParameters = {}) {
        return Requestor.run('/api/Correspondence/delete', 'DELETE',{}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    deviceGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Device/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.originalId	originalId
     * @param {string}		 queryParameters.transformationMethod	transformationMethod
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentGetById(queryParameters = {}) {
        return Requestor.run('/api/Document', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 entityId	entityId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentModalInformation(entityType, entityId, queryParameters = {}) {
        return Requestor.run('/api/Document/uploadModalInformation/{entityType}/{entityId}', 'GET', {entityType, entityId}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentStatuses(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Document/statuses', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 docId	docId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentUpload(alertId, docId, additionalOptions = {}) {
        return Requestor.run('/api/Document/{alertId}/upload/{docId}', 'POST', {alertId, docId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentMetadataDelete(id, queryParameters = {}) {
        return Requestor.run('/api/Document/metadata/{id}', 'DELETE', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentGetDocumentMetadata(id) {
        return Requestor.run('/api/Document/metadata/{id}', 'GET', {id});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.Title	Title
     * @param {undefined=}		 bodyParameters.Category	Category
     * @param {string=}		 bodyParameters.Description	Description
     * @param {undefined=}		 bodyParameters.Source	Source
     * @param {undefined}		 bodyParameters.AccessLevel	AccessLevel
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentDocumentMetadataUpdate(id, bodyParameters = {}, queryParameters = {}) {
        return Requestor.run('/api/Document/metadata/{id}', 'PUT', {id}, queryParameters, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentDownloadDocument(id, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Document/download/{id}', 'POST', {id}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentPermissions(id, queryParameters = {}) {
        return Requestor.run('/api/Document/{id}/permissions', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 bodyParameters.SearchParameters	SearchParameters
     * @param {string=}		 bodyParameters.AlertId	AlertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestGetDocumentRequests(type, id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/DocumentRequest/get-documents/{type}/{id}', 'POST', {type, id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.ClaimIds	ClaimIds
     * @param {undefined}		 bodyParameters.PageType	PageType
     * @param {integer}		 bodyParameters.AlertId	AlertId
     * @param {Object=}		 bodyParameters.FormValues	FormValues
     * @param {integer}		 bodyParameters.PageId	PageId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestRequestDocument(type, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/DocumentRequest/request-document/{type}', 'POST', {type}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestUpdate(type, alertId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/DocumentRequest/update-documents-requests/{type}/{alertId}', 'POST', {type, alertId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array=}		 queryParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestDelete(type, alertId, queryParameters = {}) {
        return Requestor.run('/api/DocumentRequest/delete/{type}/{alertId}', 'DELETE', {type, alertId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 bodyParameters.SearchParameters	SearchParameters
     * @param {string=}		 bodyParameters.AlertId	AlertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestExport(type, id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/DocumentRequest/export/{type}/{id}', 'POST', {type, id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestWorkflowHandleTransition(type, documentId, state, actionType, alertId, additionalOptions = {}) {
        return Requestor.run('/api/document/workflow/{type}/{documentId}/transition/{state}/{actionType}/{alertId}', 'POST', {type, documentId, state, actionType, alertId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 actionType	actionType
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestWorkflowActionConfiguration(actionType) {
        return Requestor.run('/api/document/workflow/configuration/{actionType}', 'GET', {actionType});
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    documentRequestWorkflowRevertTransition(documentId, type, alertId, additionalOptions = {}) {
        return Requestor.run('/api/document/workflow/{documentId}/{type}/revert/{alertId}', 'POST', {documentId, type, alertId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    electronicDeviceClaimGetClaimSummaryById(id) {
        return Requestor.run('/api/ElectronicDeviceClaim/{id}', 'GET', {id});
    }


    /**
     * @async
     * @param {string}		 enumName	enumName
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.FieldName	FieldName
     * @param {string}		 bodyParameters.TypeName	TypeName
     * @param {string=}		 bodyParameters.EnumTabFilter	EnumTabFilter
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    enumGetValues(enumName, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Enum/{enumName}', 'POST', {enumName}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    exposureGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Exposure/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    exposureCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Exposure/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    exposureSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Exposure/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    exposureComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Exposure/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    exposureQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Exposure/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Title	Title
     * @param {undefined}		 bodyParameters.Type	Type
     * @param {string=}		 bodyParameters.Description	Description
     * @param {undefined}		 bodyParameters.DueDate	DueDate
     * @param {undefined}		 bodyParameters.Status	Status
     * @param {undefined}		 bodyParameters.AccessLevel	AccessLevel
     * @param {string}		 bodyParameters.AssignedTo	AssignedTo
     * @param {undefined}		 bodyParameters.TargetType	TargetType
     * @param {integer}		 bodyParameters.TargetId	TargetId
     * @param {string}		 bodyParameters.ParentResourceCode	ParentResourceCode
     * @param {string=}		 bodyParameters.Etag	Etag
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskCreateTask(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/forcetask/create', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 targetId	targetId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskGetTasksByTarget(targetType, targetId) {
        return Requestor.run('/api/forcetask/searchByTarget/{targetType}/{targetId}', 'GET', {targetType, targetId});
    }


    /**
     * @async
     * @param {integer}		 targetId	targetId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskGetForceTaskById(id, targetType, targetId) {
        return Requestor.run('/api/forcetask/{id}/target/{targetType}/{targetId}', 'GET', {id, targetType, targetId});
    }


    /**
     * @async
     * @param {string}		 status	status
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.TargetType	TargetType
     * @param {integer}		 bodyParameters.TargetId	TargetId
     * @param {string}		 bodyParameters.Etag	Etag
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskUpdateStatus(id, status, bodyParameters = {}) {
        return Requestor.run('/api/forcetask/{id}/updateStatus/{status}', 'PUT', {id, status}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {string}		 assignee	assignee
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined}		 bodyParameters.TargetType	TargetType
     * @param {integer}		 bodyParameters.TargetId	TargetId
     * @param {string}		 bodyParameters.Etag	Etag
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskUpdateAssignee(id, assignee, bodyParameters = {}) {
        return Requestor.run('/api/forcetask/{id}/updateAssignee/{assignee}', 'PUT', {id, assignee}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Title	Title
     * @param {undefined}		 bodyParameters.Type	Type
     * @param {string=}		 bodyParameters.Description	Description
     * @param {undefined}		 bodyParameters.DueDate	DueDate
     * @param {undefined}		 bodyParameters.Status	Status
     * @param {undefined}		 bodyParameters.AccessLevel	AccessLevel
     * @param {string}		 bodyParameters.AssignedTo	AssignedTo
     * @param {undefined}		 bodyParameters.TargetType	TargetType
     * @param {integer}		 bodyParameters.TargetId	TargetId
     * @param {string}		 bodyParameters.ParentResourceCode	ParentResourceCode
     * @param {string=}		 bodyParameters.Etag	Etag
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskUpdateTask(id, bodyParameters = {}) {
        return Requestor.run('/api/forcetask/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.TargetType	TargetType
     * @param {integer}		 queryParameters.TargetId	TargetId
     * @param {string}		 queryParameters.Etag	Etag
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskDeleteTask(id, queryParameters = {}) {
        return Requestor.run('/api/forcetask/{id}', 'DELETE', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 targetId	targetId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskGetTaskAssignables(accessLevel, targetType, targetId) {
        return Requestor.run('/api/forcetask/assignables/{accessLevel}/{targetType}/{targetId}', 'GET', {accessLevel, targetType, targetId});
    }


    /**
     * @async
     * @param {string}		 enumName	enumName
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    forceTaskGetEndpointValues(enumName) {
        return Requestor.run('/api/forcetask/endpoints/{enumName}', 'GET', {enumName});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    frontIndex() {
        return Requestor.run('', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Email	Email
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    identityForgotPassword(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Identity/forgot-password', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Email	Email
     * @param {string}		 bodyParameters.Password	Password
     * @param {string=}		 bodyParameters.ConfirmPassword	ConfirmPassword
     * @param {string=}		 bodyParameters.EncryptedCode	EncryptedCode
     * @param {string=}		 bodyParameters.EncryptedId	EncryptedId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    identityResetPassword(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Identity/reset-password', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.OldPassword	OldPassword
     * @param {string}		 bodyParameters.NewPassword	NewPassword
     * @param {string}		 bodyParameters.ConfirmPassword	ConfirmPassword
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    identityChangePassword(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Identity/change-password', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Password	Password
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    identityValidatePassword(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Identity/validate-password', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    identityGetAssignables() {
        return Requestor.run('/api/Identity/assignables', 'GET', {});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intelligenceGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Intelligence/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Intermediary/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Intermediary/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Intermediary/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediarySearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Intermediary/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Intermediary/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Intermediary/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryExportHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Intermediary/export/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryGetHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Intermediary/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryGetClaimsAddresses(id, queryParameters = {}) {
        return Requestor.run('/api/Intermediary/{id}/claims/addresses', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Intermediary/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    intermediaryCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Intermediary/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    mailGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Mail/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.Content	Content
     * @param {array=}		 bodyParameters.Recipients	Recipients
     * @param {string=}		 bodyParameters.EmailSubject	EmailSubject
     * @param {string=}		 bodyParameters.TargetType	TargetType
     * @param {integer}		 bodyParameters.TargetId	TargetId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    mailerSend(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Mailer/send', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.targetTypeStr	targetTypeStr
     * @param {string}		 queryParameters.targetId	targetId
     * @param {string}		 queryParameters.locale	locale
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    mailerGetTemplates(queryParameters = {}) {
        return Requestor.run('/api/Mailer/templates', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    nationalIdentifierGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/NationalIdentifier/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Network/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object=}		 bodyParameters.UiNodes	UiNodes
     * @param {boolean}		 bodyParameters.Saved	Saved
     * @param {string=}		 bodyParameters.Name	Name
     * @param {integer}		 bodyParameters.TransformX	TransformX
     * @param {integer}		 bodyParameters.TransformY	TransformY
     * @param {number}		 bodyParameters.Scale	Scale
     * @param {number}		 bodyParameters.DeltaY	DeltaY
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkUpdate(id, bodyParameters = {}) {
        return Requestor.run('/api/Network/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetNetworkComponents(id) {
        return Requestor.run('/api/Network/{id}/components', 'GET', {id});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkUpsertNetworkUserLastVisit(id, additionalOptions = {}) {
        return Requestor.run('/api/Network/{id}/lastVisit', 'POST', {id}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateAlert(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.type	type
     * @param {integer}		 queryParameters.id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetReactiveNetworkNames(queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/getReactiveNetworkNames', 'POST', {}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Network/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.name	name
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkRename(id, queryParameters = {}) {
        return Requestor.run('/api/Network/{id}/rename', 'PUT', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 undefinedParameters.items	items
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkSetNetworkBuilderItems(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/networkBuilderItems', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetNetworkBuilderItems() {
        return Requestor.run('/api/Network/networkBuilderItems', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 undefinedParameters.models	models
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer=}		 queryParameters.unitaryPageType	unitaryPageType
     * @param {integer=}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGenerate(bodyParameters = {}, queryParameters = {}) {
        return Requestor.run('/api/Network/generate', 'PUT', {}, queryParameters, bodyParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 undefinedParameters.models	models
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGenerateUserCreated(bodyParameters = {}) {
        return Requestor.run('/api/Network/generate-user-created', 'PUT', {}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.NodesToAdd	NodesToAdd
     * @param {array=}		 bodyParameters.NodesToDelete	NodesToDelete
     * @param {array=}		 bodyParameters.EdgesToAdd	EdgesToAdd
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkAddNodesAndEdges(id, bodyParameters = {}) {
        return Requestor.run('/api/Network/add-nodes-and-edges/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 undefinedParameters.models	models
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkAddElements(id, bodyParameters = {}) {
        return Requestor.run('/api/Network/add-elements/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array=}		 queryParameters.nodeIds	nodeIds
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetNumberOfNeighbors(id, queryParameters = {}) {
        return Requestor.run('/api/Network/get-neighbors/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDeleteNodeNote(nodeId, noteId, queryParameters = {}) {
        return Requestor.run('/api/Network/node/{nodeId}/note/{noteId}', 'DELETE', {nodeId, noteId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @param {string}		 queryParameters.content	content
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkEditNodeNote(nodeId, noteId, queryParameters = {}) {
        return Requestor.run('/api/Network/node/{nodeId}/note/{noteId}', 'PUT', {nodeId, noteId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 nodeId	nodeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @param {string}		 queryParameters.content	content
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateNodeNote(nodeId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/node/{nodeId}/note', 'POST', {nodeId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDeleteEdgeNote(firstNodeId, secondNodeId, noteId, queryParameters = {}) {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}/note/{noteId}', 'DELETE', {firstNodeId, secondNodeId, noteId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @param {string}		 queryParameters.content	content
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkEditEdgeNote(firstNodeId, secondNodeId, noteId, queryParameters = {}) {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}/note/{noteId}', 'PUT', {firstNodeId, secondNodeId, noteId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 secondNodeId	secondNodeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @param {string}		 queryParameters.content	content
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateEdgeNote(firstNodeId, secondNodeId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}/note', 'POST', {firstNodeId, secondNodeId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 secondNodeId	secondNodeId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.FirstNodeUid	FirstNodeUid
     * @param {string}		 bodyParameters.SecondNodeUid	SecondNodeUid
     * @param {array}		 bodyParameters.Icons	Icons
     * @param {string=}		 bodyParameters.Note	Note
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateEdge(firstNodeId, secondNodeId, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}', 'POST', {firstNodeId, secondNodeId}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 edgeId	edgeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @param {integer}		 queryParameters.noteId	noteId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDeleteEdge(edgeId, queryParameters = {}) {
        return Requestor.run('/api/Network/edge/{edgeId}', 'DELETE', {edgeId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 sourceNodeId	sourceNodeId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.SourceNodeUid	SourceNodeUid
     * @param {string}		 bodyParameters.Label	Label
     * @param {undefined}		 bodyParameters.Icon	Icon
     * @param {undefined=}		 bodyParameters.Edge	Edge
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateNode(sourceNodeId, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/node/{sourceNodeId}', 'POST', {sourceNodeId}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 nodeId	nodeId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.SourceNodeUid	SourceNodeUid
     * @param {string}		 bodyParameters.Label	Label
     * @param {undefined}		 bodyParameters.Icon	Icon
     * @param {undefined=}		 bodyParameters.Edge	Edge
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkEditNode(nodeId, bodyParameters = {}, queryParameters = {}) {
        return Requestor.run('/api/Network/node/{nodeId}', 'PUT', {nodeId}, queryParameters, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 nodeId	nodeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDeleteNode(nodeId, queryParameters = {}) {
        return Requestor.run('/api/Network/node/{nodeId}', 'DELETE', {nodeId}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkHideNode(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/nodes/hide', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkUnhideNode(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/nodes/unhide', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 networkId	networkId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.NodesToGroupOrUngroup	NodesToGroupOrUngroup
     * @param {array=}		 bodyParameters.NodesLinked	NodesLinked
     * @param {string=}		 bodyParameters.GroupName	GroupName
     * @param {string=}		 bodyParameters.Description	Description
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateNodeGroup(networkId, bodyParameters = {}) {
        return Requestor.run('/api/Network/{networkId}/group', 'PUT', {networkId}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.NodesToGroupOrUngroup	NodesToGroupOrUngroup
     * @param {array=}		 bodyParameters.NodesLinked	NodesLinked
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkAddNodesToNodeGroup(networkId, groupId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/node-add', 'POST', {networkId, groupId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.NodesToGroupOrUngroup	NodesToGroupOrUngroup
     * @param {array=}		 bodyParameters.NodesLinked	NodesLinked
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkRemoveNodesFromNodeGroup(networkId, groupId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/node-delete', 'POST', {networkId, groupId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.NodesToGroupOrUngroup	NodesToGroupOrUngroup
     * @param {array=}		 bodyParameters.NodesLinked	NodesLinked
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkUngroupNodes(networkId, groupId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/ungroup', 'POST', {networkId, groupId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.GroupName	GroupName
     * @param {string=}		 bodyParameters.Description	Description
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkUpdateNetworkNodeGroupInfo(networkId, groupId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/group-update', 'POST', {networkId, groupId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 networkId	networkId
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.NodeGroups	NodeGroups
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDisplayGroupContent(networkId, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{networkId}/display-content', 'POST', {networkId}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {undefined}		 bodyParameters.Marker	Marker
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkInsertMarker(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/nodes/insert/marker', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {undefined}		 bodyParameters.Marker	Marker
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.networkId	networkId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDeleteMarkers(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/nodes/delete/marker', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 networkId	networkId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetRelatedNetworksAndRatio(networkId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{networkId}/related-networks-ratio', 'POST', {networkId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Network/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Network/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkClaimsComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/claims/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkClaimsByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/claims/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPersonsComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/persons/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPersonsByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/persons/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkObjectsComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/objects/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkObjectsByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/objects/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPoliciesComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/policies/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPoliciesByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/policies/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkDevicesComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/devices/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkDevicesByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/devices/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkBankAccountsComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/bankAccounts/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkBankAccountsByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/bankAccounts/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkMailsComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/mails/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkMailsByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/mails/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkAddressesComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/addresses/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkAddressesByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/addresses/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPhoneNumbersComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/phoneNumbers/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPhoneNumbersByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/phoneNumbers/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPolicyQuotesComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/policyQuotes/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPolicyQuotesByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/policyQuotes/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkProvidersComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/providers/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkProvidersByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/providers/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntermediariesComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/intermediaries/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntermediariesByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/intermediaries/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkNationalIdentifiersComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/nationalIdentifiers/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkNationalIdentifiersByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/nationalIdentifiers/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntelligencesComputedView(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/intelligences/computed-view', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @param {array=}		 bodyParameters.Roots	Roots
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntelligencesByElementIdsComputedView(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/elements/intelligences/computed-view', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetExportsList() {
        return Requestor.run('/api/NetworkDetailsTable/exports', 'GET', {});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableGetExportsListExploration() {
        return Requestor.run('/api/NetworkDetailsTable/exportsExploration', 'GET', {});
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableExportNetworkElement(id, type, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/{id}/export/{type}', 'POST', {id, type}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 type	type
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object=}		 bodyParameters.Exports	Exports
     * @param {string=}		 bodyParameters.SeedLabel	SeedLabel
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkDetailsTableExportNetworkElementsByElementIds(type, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/NetworkDetailsTable/export/elements/{type}', 'POST', {type}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkExternalEntityGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/NetworkExternalEntity/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    networkNodeGroupGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/NetworkNodeGroup/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Object/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Object/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Object/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 claimId	claimId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {integer}		 queryParameters.historyType	historyType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectGetByIdByClaimId(id, claimId, queryParameters = {}) {
        return Requestor.run('/api/Object/{id}/claim/{claimId}', 'GET', {id, claimId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectExportHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Object/export/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectGetHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Object/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectGetClaimsAddresses(id, queryParameters = {}) {
        return Requestor.run('/api/Object/{id}/claims/addresses', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Object/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Object/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Object/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Object/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    objectCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Object/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 undefinedParameters.models	models
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    permissionCan(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Permission/can', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Person/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Person/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetPersonAsRecoveryPayerById(id, queryParameters = {}) {
        return Requestor.run('/api/Person/{id}/as-recovery-payer', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Person/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 claimId	claimId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {integer}		 queryParameters.historyType	historyType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetByIdByClaimId(id, claimId, queryParameters = {}) {
        return Requestor.run('/api/Person/{id}/Claim/{claimId}', 'GET', {id, claimId}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personExportHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Person/export/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Person/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetClaimsAddresses(id, queryParameters = {}) {
        return Requestor.run('/api/Person/{id}/claims/addresses', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetInsurersContacts() {
        return Requestor.run('/api/Person/get-insurers-contacts', 'GET', {});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetComputedViewInsurersContacts() {
        return Requestor.run('/api/Person/insurers-contacts/computed-view', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Person/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Person/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Person/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Person/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    personCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Person/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    phoneNumberGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/PhoneNumber/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Policy/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyGetPolicyHolderAsRecoveryPayerById(id, queryParameters = {}) {
        return Requestor.run('/api/Policy/{id}/as-recovery-payer', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Policy/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Policy/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyExportHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Policy/export/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyGetHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Policy/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyGetClaimsAddresses(id, queryParameters = {}) {
        return Requestor.run('/api/Policy/{id}/claims/addresses', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policySearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Policy/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Policy/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Policy/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Policy/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Policy/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    policyQuoteGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/PolicyQuote/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.key	key
     * @param {string}		 queryParameters.value	value
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    preferenceUpsertUnstructuredPreference(queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Preference/upsertUnstructuredPreference', 'POST', {}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 preferenceId	preferenceId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    preferenceDeletePreference(preferenceId) {
        return Requestor.run('/api/Preference/delete/{preferenceId}', 'DELETE', {preferenceId});
    }


    /**
     * @async
     * @param {integer}		 preferenceId	preferenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.newName	newName
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    preferenceRenamePreference(preferenceId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Preference/rename/{preferenceId}', 'POST', {preferenceId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string}		 bodyParameters.Key	Key
     * @param {undefined}		 bodyParameters.Value	Value
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {boolean}		 queryParameters.isDefault	isDefault
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    preferenceUpsertStructuredPreference(bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Preference/upsertStructuredPreference', 'POST', {}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 preferenceId	preferenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {boolean}		 queryParameters.unset	unset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    preferenceSetPreferenceAsDefault(preferenceId, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Preference/setAsDefault/{preferenceId}', 'POST', {preferenceId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerGetById(id, queryParameters = {}) {
        return Requestor.run('/api/Provider/{id}', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerGetNetworkNodeById(id, queryParameters = {}) {
        return Requestor.run('/api/Provider/{id}/as-network-node', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerGetProviderAsRecoveryPayerById(id, queryParameters = {}) {
        return Requestor.run('/api/Provider/{id}/as-recovery-payer', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.Values	Values
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerCreateAlert(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Provider/{id}/create-alert', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerGetClaimsAddresses(id, queryParameters = {}) {
        return Requestor.run('/api/Provider/{id}/claims/addresses', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerExportHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Provider/export/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.pageType	pageType
     * @param {string=}		 queryParameters.EqualityContract	EqualityContract
     * @param {integer=}		 queryParameters.Year	Year
     * @param {integer=}		 queryParameters.Month	Month
     * @param {integer=}		 queryParameters.Day	Day
     * @param {integer=}		 queryParameters.Hour	Hour
     * @param {integer=}		 queryParameters.Minute	Minute
     * @param {integer=}		 queryParameters.Second	Second
     * @param {integer=}		 queryParameters.Millisecond	Millisecond
     * @param {string=}		 queryParameters.Offset	Offset
     * @param {integer=}		 queryParameters.scenarioId	scenarioId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerGetHistory(id, bodyParameters = {}, queryParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Provider/{id}/history', 'POST', {id}, queryParameters, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Provider/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Provider/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.query	query
     * @param {integer=}		 queryParameters.start	start
     * @param {integer=}		 queryParameters.size	size
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerQuickSearch(queryParameters = {}) {
        return Requestor.run('/api/Provider/quicksearch', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.alertId	alertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerGetDocuments(id, queryParameters = {}) {
        return Requestor.run('/api/Provider/{id}/documents', 'GET', {id}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.AlertId	AlertId
     * @param {array=}		 bodyParameters.DocumentsMetadata	DocumentsMetadata
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    providerCreateDocuments(id, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Provider/{id}/documents', 'POST', {id}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    purgedAlertGetReferenceCodes() {
        return Requestor.run('/api/PurgedAlert/referencecodes', 'GET', {});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    purgedAlertGetById(id) {
        return Requestor.run('/api/PurgedAlert/{id}', 'GET', {id});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {number}		 bodyParameters.MaterialAmount	MaterialAmount
     * @param {number}		 bodyParameters.CorporalAmount	CorporalAmount
     * @param {array=}		 bodyParameters.Workflows	Workflows
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    purgedAlertSave(id, bodyParameters = {}) {
        return Requestor.run('/api/PurgedAlert/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryGetByClaimIds(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/get-by-claim-ids', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {undefined=}		 bodyParameters.AtDate	AtDate
     * @param {undefined}		 bodyParameters.RecoveryTableType	RecoveryTableType
     * @param {undefined}		 bodyParameters.PageType	PageType
     * @param {string=}		 bodyParameters.PageId	PageId
     * @param {string=}		 bodyParameters.AlertId	AlertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoverySearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {undefined=}		 bodyParameters.AtDate	AtDate
     * @param {undefined}		 bodyParameters.RecoveryTableType	RecoveryTableType
     * @param {undefined}		 bodyParameters.PageType	PageType
     * @param {string=}		 bodyParameters.PageId	PageId
     * @param {string=}		 bodyParameters.AlertId	AlertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.RecoveryId	RecoveryId
     * @param {integer}		 bodyParameters.AlertId	AlertId
     * @param {boolean}		 bodyParameters.IsFullRecovery	IsFullRecovery
     * @param {undefined=}		 bodyParameters.RecoveryType	RecoveryType
     * @param {undefined=}		 bodyParameters.RecoveryMotive	RecoveryMotive
     * @param {undefined=}		 bodyParameters.TotalReclaimedAmount	TotalReclaimedAmount
     * @param {undefined=}		 bodyParameters.TotalRecoveredAmount	TotalRecoveredAmount
     * @param {undefined=}		 bodyParameters.Recipient	Recipient
     * @param {array=}		 bodyParameters.RecoveryDetailsCreateModels	RecoveryDetailsCreateModels
     * @param {array=}		 bodyParameters.FollowupWorkflows	FollowupWorkflows
     * @param {undefined}		 bodyParameters.PaymentStatus	PaymentStatus
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryCreate(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/create', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.RecoveryPayerId	RecoveryPayerId
     * @param {undefined}		 bodyParameters.RecoveryPayerType	RecoveryPayerType
     * @param {string=}		 bodyParameters.FirstName	FirstName
     * @param {string=}		 bodyParameters.LastName	LastName
     * @param {string=}		 bodyParameters.Civility	Civility
     * @param {string=}		 bodyParameters.FullAddress	FullAddress
     * @param {string=}		 bodyParameters.PostCode	PostCode
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryCreateRecoveryPayer(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/create-recovery-payer', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer=}		 bodyParameters.RecoveryId	RecoveryId
     * @param {integer}		 bodyParameters.AlertId	AlertId
     * @param {boolean}		 bodyParameters.IsFullRecovery	IsFullRecovery
     * @param {undefined=}		 bodyParameters.RecoveryType	RecoveryType
     * @param {undefined=}		 bodyParameters.RecoveryMotive	RecoveryMotive
     * @param {undefined=}		 bodyParameters.TotalReclaimedAmount	TotalReclaimedAmount
     * @param {undefined=}		 bodyParameters.TotalRecoveredAmount	TotalRecoveredAmount
     * @param {undefined=}		 bodyParameters.Recipient	Recipient
     * @param {array=}		 bodyParameters.RecoveryDetailsCreateModels	RecoveryDetailsCreateModels
     * @param {array=}		 bodyParameters.FollowupWorkflows	FollowupWorkflows
     * @param {undefined}		 bodyParameters.PaymentStatus	PaymentStatus
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryUpdate(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/update', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryGetById(id) {
        return Requestor.run('/api/Recovery/{id}', 'GET', {id});
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryDeleteById(queryParameters = {}) {
        return Requestor.run('/api/Recovery/delete', 'DELETE',{}, queryParameters);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array}		 bodyParameters.Ids	Ids
     * @param {undefined=}		 bodyParameters.AtDate	AtDate
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryHandleBulkUpdate(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/handleBulkUpdate/lastClaimedDateAndNumberOfRequests', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 exportName	exportName
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {undefined=}		 bodyParameters.AtDate	AtDate
     * @param {undefined}		 bodyParameters.RecoveryTableType	RecoveryTableType
     * @param {undefined}		 bodyParameters.PageType	PageType
     * @param {string=}		 bodyParameters.PageId	PageId
     * @param {string=}		 bodyParameters.AlertId	AlertId
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryTableCustomExport(exportName, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Recovery/tableCustomExport/{exportName}', 'POST', {exportName}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 role	role
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {undefined=}		 undefinedParameters.body	body
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryWorkflowHandleTransition(recoveryId, role, state, actionType, bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/recovery/state/{recoveryId}/{role}/transition/{state}/{actionType}', 'POST', {recoveryId, role, state, actionType}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 role	role
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryWorkflowRevertTransition(recoveryId, role, additionalOptions = {}) {
        return Requestor.run('/api/recovery/state/{recoveryId}/{role}/revert', 'POST', {recoveryId, role}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 role	role
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryWorkflowActionConfiguration(role, actionType) {
        return Requestor.run('/api/recovery/state/configuration/{role}/{actionType}', 'GET', {role, actionType});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {string=}		 bodyParameters.NextState	NextState
     * @param {string=}		 bodyParameters.Action	Action
     * @param {undefined=}		 bodyParameters.Parameters	Parameters
     * @param {array=}		 bodyParameters.Ids	Ids
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    recoveryWorkflowHandleBulkTransition(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/recovery/state/transition/bulk', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    reminderGetAllReminders() {
        return Requestor.run('/api/Reminder', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.ReferenceId	ReferenceId
     * @param {undefined}		 bodyParameters.TypeId	TypeId
     * @param {undefined}		 bodyParameters.Date	Date
     * @param {string}		 bodyParameters.Comment	Comment
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    reminderAddReminder(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Reminder', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    reminderGetReminder(type, id) {
        return Requestor.run('/api/Reminder/{type}/{id}', 'GET', {type, id});
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.ReferenceId	ReferenceId
     * @param {undefined}		 bodyParameters.TypeId	TypeId
     * @param {undefined}		 bodyParameters.Date	Date
     * @param {string}		 bodyParameters.Comment	Comment
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    reminderUpdateReminder(id, bodyParameters = {}) {
        return Requestor.run('/api/Reminder/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    reminderRemoveReminder(id) {
        return Requestor.run('/api/Reminder/{id}', 'DELETE', {id});
    }


    /**
     * @async
     * @param {string}		 dashboard	dashboard
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    reportingGetEncryptedClaims(dashboard) {
        return Requestor.run('/api/Reporting/encrypted-claims/{dashboard}', 'GET', {dashboard});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    resourceGetLastImportDate() {
        return Requestor.run('/lastimportdate', 'GET', {});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedClaimSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/ClaimSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedClaimComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/ClaimComputedViewSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedIntermediarySearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/IntermediarySearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedIntermediaryComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/IntermediaryComputedViewSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedNetworkSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/NetworkSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedObjectSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/ObjectSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedObjectComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/ObjectComputedViewSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedPersonSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/PersonSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedPersonComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/PersonComputedViewSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedPolicySearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/PolicySearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedPolicyComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/PolicyComputedViewSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedProviderSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/ProviderSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {Object}		 bodyParameters.ComplianceQuestions	ComplianceQuestions
     * @param {undefined=}		 bodyParameters.ParametersModel	ParametersModel
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    restrictedProviderComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/Restricted/ProviderComputedViewSearch', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined=}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    smartDocumentGetSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/SmartDocument/search', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {array=}		 bodyParameters.Filters	Filters
     * @param {integer}		 bodyParameters.Length	Length
     * @param {undefined=}		 bodyParameters.Sort	Sort
     * @param {integer}		 bodyParameters.Start	Start
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    smartDocumentGetComputedViewSearch(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/SmartDocument/search/computed-view', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.Id	Id
     * @param {undefined}		 bodyParameters.Type	Type
     * @param {string=}		 bodyParameters.FirstName	FirstName
     * @param {string=}		 bodyParameters.LastName	LastName
     * @param {undefined=}		 bodyParameters.BirthDate	BirthDate
     * @param {string=}		 bodyParameters.BusinessName	BusinessName
     * @param {string=}		 bodyParameters.BusinessIdentificationNumber	BusinessIdentificationNumber
     * @param {string=}		 bodyParameters.Address	Address
     * @param {string=}		 bodyParameters.PhoneNumber	PhoneNumber
     * @param {string=}		 bodyParameters.BankAccount	BankAccount
     * @param {string=}		 bodyParameters.Email	Email
     * @param {string=}		 bodyParameters.VIN	VIN
     * @param {string=}		 bodyParameters.RegistrationPlate	RegistrationPlate
     * @param {string=}		 bodyParameters.Reason	Reason
     * @param {string=}		 bodyParameters.Severity	Severity
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    smartDocumentCreate(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/SmartDocument', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.Id	Id
     * @param {undefined}		 bodyParameters.Type	Type
     * @param {string=}		 bodyParameters.FirstName	FirstName
     * @param {string=}		 bodyParameters.LastName	LastName
     * @param {undefined=}		 bodyParameters.BirthDate	BirthDate
     * @param {string=}		 bodyParameters.BusinessName	BusinessName
     * @param {string=}		 bodyParameters.BusinessIdentificationNumber	BusinessIdentificationNumber
     * @param {string=}		 bodyParameters.Address	Address
     * @param {string=}		 bodyParameters.PhoneNumber	PhoneNumber
     * @param {string=}		 bodyParameters.BankAccount	BankAccount
     * @param {string=}		 bodyParameters.Email	Email
     * @param {string=}		 bodyParameters.VIN	VIN
     * @param {string=}		 bodyParameters.RegistrationPlate	RegistrationPlate
     * @param {string=}		 bodyParameters.Reason	Reason
     * @param {string=}		 bodyParameters.Severity	Severity
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    smartDocumentUpdate(id, bodyParameters = {}) {
        return Requestor.run('/api/SmartDocument/{id}', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    smartDocumentDelete(id) {
        return Requestor.run('/api/SmartDocument/{id}', 'DELETE', {id});
    }


    /**
     * @async
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.AlertId	AlertId
     * @param {integer}		 bodyParameters.PageId	PageId
     * @param {string=}		 bodyParameters.PageType	PageType
     * @param {Object}		 bodyParameters.FormValues	FormValues
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    structuredNoteCreateNote(bodyParameters = {}, additionalOptions = {}) {
        return Requestor.run('/api/StructuredNote/create-note', 'POST', {}, {}, bodyParameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 bodyParameters	This parameter will be add in the body of the request
     * @param {integer}		 bodyParameters.AlertId	AlertId
     * @param {integer}		 bodyParameters.PageId	PageId
     * @param {string=}		 bodyParameters.PageType	PageType
     * @param {Object}		 bodyParameters.FormValues	FormValues
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    structuredNoteUpdateNoteById(id, bodyParameters = {}) {
        return Requestor.run('/api/StructuredNote/{id}/update-note', 'PUT', {id}, {}, bodyParameters);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 queryParameters.id	id
     * @param {string}		 queryParameters.pageType	pageType
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    structuredNoteDeleteNoteById(queryParameters = {}) {
        return Requestor.run('/api/StructuredNote/delete', 'DELETE',{}, queryParameters);
    }


    /**
     * @async
     * @param {string}		 endpoint	endpoint
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    structuredNoteGetEndpointValues(endpoint) {
        return Requestor.run('/api/StructuredNote/endpoints/{endpoint}', 'GET', {endpoint});
    }


    /**
     * @async
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    traceabilityLogsGetTraceabilityLogsStatistics() {
        return Requestor.run('/api/TraceabilityLogs/statistics', 'GET', {});
    }


    /**
     * @async
     * @param {string}		 fromDate	fromDate
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    traceabilityLogsGetTraceabilityLogsStatisticsFromDate(fromDate) {
        return Requestor.run('/api/TraceabilityLogs/statistics/{fromDate}', 'GET', {fromDate});
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 queryParameters.culture	culture
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    translationGetByCulture(queryParameters = {}) {
        return Requestor.run('/api/Translation', 'GET', {}, queryParameters);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise.<object, Error>}		 A cancellable promise
     */
    userCreatedGetUserCreatedAsRecoveryPayerById(id) {
        return Requestor.run('/api/UserCreated/{id}/as-recovery-payer', 'GET', {id});
    }
}

const api = new API();

/* eslint-enable */
export default api;
