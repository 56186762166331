import Promise from "bluebird";
import { APIError, AuthorizationError, ServerError } from "@app/utility/errors";
import { isJsonResponse } from "@app/utility/xhr-request-helper";
import { parseJSON } from "@utility/json-helper";

/**
 * This is required as cypress is having issues resolving bluebird due to
 * conflicting versions between ForceUI and a nested dependency.
 *
 * Basically when cypress is running it's providing the wrong instance for
 * Promise and it does not allow for cancellation, so we are enabling it manually.
 */
const cypressCancellationFix = (PromiseInstance) => {
    PromiseInstance.config({ cancellation: true });
};

const getCSRFToken = () => {
    const cookies = document.cookie.split("; ");

    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");

        if (cookieName === "CSRF-TOKEN-2") {
            return cookieValue;
        }
    }

    return null;
};

class Requestor {
    constructor() {
        this.defaultQueryParameters = {
            "X-Requested-With": "XMLHttpRequest",
        };
    }

    /**
     * @param {string} pattern pattern of the url
     * @param {Object} pathParams parameters of the path
     * @param {Object} queryParameters parameters of the queryParameters
     * @return {string} Url
     */
    buildUrl(pattern, pathParams = {}, queryParameters = {}) {
        const path = this.buildPathUrl(pattern, pathParams);
        const query = this.buildQueryUrl({
            ...this.defaultQueryParameters,
            ...queryParameters,
        });

        return `${path}?${query}`;
    }

    buildPathUrl(pattern, params) {
        return pattern.replace(/(\{[a-zA-Z0-9_]+\})/g, (match, group) => params[group.substr(1, group.length - 2)]);
    }

    // http://stackoverflow.com/a/1714899/5410019
    buildQueryUrl(obj, prefix) {
        const str = [];
        let prop = {};
        const plainObj = JSON.parse(JSON.stringify(obj));
        for (prop in plainObj) {
            if (plainObj.hasOwnProperty(prop)) {
                const key = prefix ? `${prefix}[${prop}]` : prop;
                const val = plainObj[prop];
                str.push(
                    val !== null && typeof val === "object"
                        ? this.buildQueryUrl(val, key)
                        : `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                );
            }
        }
        return str.join("&");
    }

    run(pattern, method, pathParams = {}, queryParameters = {}, body = null, additionalOptions = {}) {
        const url = this.buildUrl(pattern, pathParams, queryParameters);

        cypressCancellationFix(Promise);

        return new Promise((resolve, reject, onCancel) => {
            const xhrRequest = new XMLHttpRequest();

            onCancel(() => xhrRequest.abort());
            xhrRequest.onreadystatechange = () => {
                if (xhrRequest.readyState === XMLHttpRequest.DONE) {
                    if (xhrRequest.status >= 200 && xhrRequest.status < 300) {
                        return resolve(xhrRequest);
                    }

                    if (xhrRequest.status === 400) {
                        return reject(new APIError(400, xhrRequest));
                    }
                    if (xhrRequest.status === 401) {
                        window.location.reload();
                        return null;
                    }
                    if (xhrRequest.status === 403) {
                        return reject(new AuthorizationError("Unauthorized access", xhrRequest));
                    }
                    if (xhrRequest.status >= 500) {
                        return reject(new ServerError("Server error", xhrRequest));
                    }

                    return reject(new APIError("Unhandled error", xhrRequest));
                }

                return null;
            };

            xhrRequest.withCredentials = true;

            xhrRequest.open(method, url, true);
            if (method !== "GET") {
                const CSRFtoken = getCSRFToken();

                if (CSRFtoken !== null) {
                    xhrRequest.setRequestHeader("X-CSRF-TOKEN", CSRFtoken);
                }
            }

            if (additionalOptions.formData) {
                // Note: we adapted our code to intercept the abort signal - the XHR API does not support yet attaching it directly to the call
                additionalOptions.signal.addEventListener("abort", () => xhrRequest.abort(), { once: true });

                xhrRequest.upload.addEventListener("progress", additionalOptions.onProgressHandler, false);

                xhrRequest.setRequestHeader("Accept", "application/json, text/plain, multipart/form-data, */*");

                xhrRequest.send(additionalOptions.formData);
            } else {
                xhrRequest.setRequestHeader("Accept", "application/json, text/plain, */*");
                xhrRequest.setRequestHeader("Content-Type", "application/json; charset=utf-8; text/html");

                if (additionalOptions.isDownloadFileChunkByRange) {
                    xhrRequest.responseType = "blob";

                    if (additionalOptions.range) {
                        xhrRequest.setRequestHeader("Range", additionalOptions.range);
                    }
                }

                if (body === null) {
                    xhrRequest.send();
                } else {
                    xhrRequest.send(JSON.stringify(body));
                }
            }
        })
            .then((xhrRequest) => {
                if (additionalOptions.isDownloadFileChunkByRange) {
                    return Promise.resolve(xhrRequest);
                }

                const response = xhrRequest.response;

                if (isJsonResponse(xhrRequest)) {
                    return parseJSON(response);
                }
                if (typeof response === "string") {
                    if (response.length === 0) {
                        return Promise.resolve();
                    }
                    return Promise.resolve(response);
                }

                return Promise.reject(new Error("Response seems to be a format not handled by the application."));
            })
            .catch(APIError, (error) => {
                const xhrRequest = error.getXHRRequest();

                if (isJsonResponse(xhrRequest)) {
                    return parseJSON(xhrRequest.response).then(
                        (response) => {
                            error.response = response;

                            return Promise.reject(error);
                        },
                        (jsonError) => Promise.reject(jsonError)
                    );
                }

                return Promise.reject(error);
            })
            .catch(ServerError, (error) => {
                // logging service
                if (process.env.NODE_ENV !== "production") {
                    const xhrRequest = error.getXHRRequest();

                    if (isJsonResponse(xhrRequest)) {
                        return parseJSON(xhrRequest.response).then((response) => {
                            if (response.type === "dbsetup") {
                                alert(response.error);
                            } else {
                                console.error(`Error ${xhrRequest.status}: ${response.Error}\n${response.StackTrace}`);
                            }

                            return Promise.reject(error);
                        });
                    }
                }

                return Promise.reject(error);
            });
    }
}

const requestor = new Requestor();

export default requestor;
