const ServerError = function (message, xhrRequest = {}) {
    if (Error.captureStackTrace) {
        Error.captureStackTrace(this);
    }

    this.message = String(message);
    this.xhrRequest = xhrRequest;
    this.status = xhrRequest.status;
    this.name = "ServerError";
};

ServerError.prototype = Object.create(Error.prototype);

ServerError.prototype.getXHRRequest = function () {
    return this.xhrRequest;
};

export default ServerError;
