import BlueBird from "bluebird";

BlueBird.config({
    cancellation: true,
    monitoring: false,
    warnings: false,
});

window.Promise = BlueBird;

// Custom shift extension -- durld
window.Promise.prototype.always = function (callback) {
    return this.tap((val) => callback(true, val)).tapCatch((err) => callback(false, err));
};
