const AuthorizationError = function (message, xhrRequest = {}) {
    if (Error.captureStackTrace) {
        Error.captureStackTrace(this);
    }

    this.message = String(message);
    this.xhrRequest = xhrRequest;
    this.status = xhrRequest.status;
    this.name = "AuthorizationError";
};

AuthorizationError.prototype = Object.create(Error.prototype);

AuthorizationError.prototype.getXHRRequest = function () {
    return this.xhrRequest;
};

export default AuthorizationError;
