const APIError = function (message, xhrRequest = {}, response = null) {
    if (Error.captureStackTrace) {
        Error.captureStackTrace(this);
    }

    this.message = String(message);
    this.xhrRequest = xhrRequest;
    this.status = xhrRequest.status;
    this.response = response;
    this.name = "APIError";
};

APIError.prototype = Object.create(Error.prototype);

APIError.prototype.getXHRRequest = function () {
    return this.xhrRequest;
};

APIError.prototype.getResponse = function () {
    return this.response;
};

export default APIError;
